import { Box, Typography } from '@mui/material'
import Board from './Board'
import { useContext, useEffect, useState } from 'react'
import ticketApi from '../../api/ticketApi'
import { KMSessionUserContext } from "stores/KMSessionUserProvider";
import { KMInfraContext } from 'stores/KMInfraProvider'


const Project = (props) => {
    const { sessionUserStore } = useContext(KMSessionUserContext);
    const { clientId } = sessionUserStore
    const [tickets, setTickets] = useState()
    const [bug, setBug] = useState([])
    const [functionality, setFunctionality] = useState([])
    const { infraStore } = useContext(KMInfraContext)
    const [genericFunctionalityStates, setGenericFunctionalityStates] = useState([])
    const [genericBugStates, setGenericBugStates] = useState([])

    useEffect(() => {
        ticketApi.getAllActive().then((data) => {
            if (props.singleView) {
                setTickets(data.datas.filter(ticket => ticket.ticketDeleteDatetime === null))
            } else {
                setTickets(data.datas.filter(ticket => ticket.clientId === props.clientId).filter(ticket => ticket.ticketDeleteDatetime === null))
            }
        })
    }, [])

    useEffect(() => {
        let { typings } = infraStore
        let functionalityStates = typings.filter(typing => typing.typingTargetColumn === "typingCodeFunctionalityState")
        let bugStates = typings.filter(typing => typing.typingTargetColumn === "typingCodeBugState")
        setGenericFunctionalityStates(functionalityStates)
        setGenericBugStates(bugStates)
    }, [])



    useEffect(() => {

        if (!tickets) return

        let bugSections = []
        let functionalitySections = []

        genericFunctionalityStates.map(functionalityState => {
            functionalitySections.push({
                typingCode: functionalityState.typingCode,
                tickets: []
            })
            tickets && tickets.map(ticket => {
                if (functionalityState.typingCode === ticket.typingCodeFunctionalityState) {
                        functionalitySections.find(functionalitySection => {
                            if (functionalitySection.typingCode === functionalityState.typingCode)
                                functionalitySection.tickets.push(ticket)
                        })
                }
            })
        })

        genericBugStates.map(genericBugState => {
            bugSections.push({
                typingCode: genericBugState.typingCode,
                tickets: []
            })
            tickets && tickets.map(ticket => {
                if (genericBugState.typingCode === ticket.typingCodeBugState) {
                        bugSections.find(bugSection => {
                            if (bugSection.typingCode === genericBugState.typingCode)
                                bugSection.tickets.push(ticket)
                        })
                }
            })
        })
        // On met à jour les états
        setBug(bugSections)
        setFunctionality(functionalitySections)
    }, [tickets])
    return (
        <Box sx={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            overflowY: "scroll"
        }}>

            <Typography variant="h4" sx={{ marginBottom: '20px' }}>
                Évolution du produit - Fonctionnalités
            </Typography>
            <Board data={functionality} key="functionality"/>

            <Typography variant="h4" sx={{ marginBottom: '20px' }}>
                Exploitation - Bugs
            </Typography>
            <Board data={bug} key="bug" />
        </Box >
    )
}

export default Project
