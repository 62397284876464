import { getTokenPopup, getTokenRedirect, verifyIsLoggedIn, authRequestOfflineScope, authRequestCommonScopes } from "./graphUserAuthUtils";

export default async function graphUserRequest ({ method = "get", endpoint, body = {} ,contentType = "application/json" }) {
  if (endpoint.indexOf('https://') === -1) {
    endpoint = `https://graph.microsoft.com/v1.0/${endpoint}`;
  }
  // console.log(`[MICROSOFT GRAPH]: new client request to ${endpoint} at : ` + new Date().toString());

    return verifyIsLoggedIn()
        .then(() => {
            return getTokenPopup(authRequestCommonScopes)
              .then(tokenResponse => {
                if (tokenResponse === null || tokenResponse.accessToken === null) {
                  throw new Error("Could not retrieve access token");
                }
                const headers = new Headers();
                const bearer = `Bearer ${tokenResponse.accessToken}`;

                headers.append("Authorization", bearer);
                headers.append("Content-Type", contentType);
                headers.append("Access-Control-Allow-Origin", "*");

                const options = {
                    method,
                    headers,
                };

                if (method !== "get") {
                  options.body = JSON.stringify(body);
                }

                return fetch(endpoint, options)
                  .then(response => {
                    if (response.headers.get("content-type").indexOf("application/json") !== -1) {
                      return response.json()
                    } else {
                      return response.blob()
                    }
                  })
                  .then(data => {
                    return data;
                  })
                  .catch(error => console.log(error));
              }
            )
        })
    .catch(error => {
      console.log(error);
      return Promise.reject(error);
    })
}

// WORKFLOW : 
// DO YOU HAVE A VALID TOKEN ?
// YES : CALL LOCAL API TO MAKE REQUEST TO MICROSOFT API AS USER

// NO : CALL MICROSOFT API TO GET TOKEN
// STORE TOKEN IN LOCAL STORAGE
// CALL LOCAL API TO MAKE REQUEST TO MICROSOFT API AS USER
