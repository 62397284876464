import React from "react"

const sqlDictionnary = ["AND", "AS", "ASC", "BY", "CASE", "CONCAT", "DESC", "DISTINCT", "ELSE", "END", "FROM", "GROUP", "INNER", "IS", "IN", "JOIN", "LIMIT", "NOT", "NULL", "ON", "OR", "ORDER", "SELECT", "THEN", "UNION", "WHEN", "WHERE"]
const sqlMinorRequest = ["AND", "AS", "BY", "DISTINCT", "IS", "IN", "LIMIT", "ON", "OR", "ORDER", "GROUP"]
const sqlMajorRequest = ["AS", "CASE", "CONCAT", "FROM", "GROUP", "LIMIT", "ORDER", "SELECT", "WHERE"]
const sqlMultipleWords = ["AND", "IS", "IN", "NOT", "NULL", "ORDER", "GROUP", "BY", "WHEN", "ON", "INNER", "JOIN", "DISTINCT", "ELSE", "END", "UNION"]

const ReactMinorOrMajorSQL = (sqlPart) => {
    return !sqlMinorRequest.includes(sqlPart);
}

export const SQLColorFormatter = (sql) => {
    const sqlReconfigured = sql.replace(/[\n\t]/g, " ")
    const sqlRereconfigured = sqlReconfigured.replace("(", " ( ")
    const sqlRequest = sqlRereconfigured.split(' ');
    const sqlStr = [];
    let i = 0;
    let tabCpt = 0;
    let isMajor = false;

    sqlRequest.forEach(sqlRequestPart => {
        const sqlPart = sqlRequestPart.toUpperCase();
        if (sqlPart.includes('(')) tabCpt++
        else if (sqlPart.includes(')')) tabCpt--
        if (sqlDictionnary.includes(sqlPart)) {


            if (!sqlMultipleWords.includes(sqlPart)) {
                isMajor = ReactMinorOrMajorSQL(sqlPart)
                sqlStr.push(isMajor ? (<br key={"br" + i} />) : (<React.Fragment key={"fragment" + i}><br />&emsp;</React.Fragment>))
            }
            if (sqlMajorRequest.includes(sqlPart)) {
                for (let j = 0; j < tabCpt; j++) {
                    sqlStr.push(<React.Fragment key={"fragment" + i.toString() + i + j.toString()}>&emsp;</React.Fragment>)
                }

            }

            sqlStr.push(<span key={"span" + i} className='sql-key'> {sqlPart}</span>)

        } else if ((sqlPart.match(/[\`\`]/g) || sqlPart.match(/[\"\"]/g) || sqlPart.match(/[\'\']/g)) && sqlPart !== "'" && sqlPart !== "',") { //eslint-disable-line
            sqlStr.push(<span key={"span" + i} className='sql-string'> {sqlRequestPart}</span>)
        } else if (!isNaN(sqlPart) && Number.isInteger(parseFloat(sqlPart))) {
            sqlStr.push(<span key={"span" + i} className='sql-number'> {sqlRequestPart}</span>)
        } else {
            sqlStr.push(<span key={"span" + i} style={{ color: 'white' }}> {sqlRequestPart}</span>);
        }
        i++
    });
    return sqlStr;
}

export const SQLFormatter = (sql) => {
    const sqlReconfigured = sql.replace(/[\n\t]/g, " ")
    const sqlRequest = sqlReconfigured.split(' ');
    const sqlStr = [""];

    sqlRequest.forEach(sqlPart => {
        if (sqlDictionnary.includes(sqlPart)) {

            if (!sqlMultipleWords.includes(sqlPart)) sqlStr.push(ReactMinorOrMajorSQL(sqlPart))

            sqlStr.push(sqlPart)
        } else {
            sqlStr.push(" " + sqlPart);
        }
    });
    // console.log(sqlStr)
    return sqlStr;
}

export const scrollToBottom = (selector = '.update') => {
    document.querySelector(selector) ? document.querySelector(selector).scrollIntoView({
        left: 0,
        behavior: 'smooth'
    }) :
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            left: 0,
            behavior: 'smooth'//auto,
        });
};

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function makeHuman(string) {

    return (string.split('/').pop().charAt(0).toUpperCase()
    +
    string.split('/').pop().substring(1).split(/(?=[A-Z])/).join(" "))
}

export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}


export const RGB_Linear_Blend=(p,c0,c1)=>{
	var i=parseInt,r=Math.round,P=1-p,[a,b,c,d]=c0.split(","),[e,f,g,h]=c1.split(","),x=d||h,j=x?","+(!d?h:!h?d:r((parseFloat(d)*P+parseFloat(h)*p)*1000)/1000+")"):")";
	return"rgb"+(x?"a(":"(")+r(i(a[3]=="a"?a.slice(5):a.slice(4))*P+i(e[3]=="a"?e.slice(5):e.slice(4))*p)+","+r(i(b)*P+i(f)*p)+","+r(i(c)*P+i(g)*p)+j;
}

export const RGB_Log_Blend=(p,c0,c1)=>{
	var i=parseInt,r=Math.round,P=1-p,[a,b,c,d]=c0.split(","),[e,f,g,h]=c1.split(","),x=d||h,j=x?","+(!d?h:!h?d:r((parseFloat(d)*P+parseFloat(h)*p)*1000)/1000+")"):")";
	return"rgb"+(x?"a(":"(")+r((P*i(a[3]=="a"?a.slice(5):a.slice(4))**2+p*i(e[3]=="a"?e.slice(5):e.slice(4))**2)**0.5)+","+r((P*i(b)**2+p*i(f)**2)**0.5)+","+r((P*i(c)**2+p*i(g)**2)**0.5)+j;
}

export function lightOrDark(color) {

    // Variables for red, green, blue values
    var r, g, b, hsp;
    
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        
        r = color[1];
        g = color[2];
        b = color[3];
    } 
    else {
        
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }
    
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>127.5) {

        return 'light';
    } 
    else {

        return 'dark';
    }
}

export function formatMailBody (data) {
    if (!data.body) return data

    // Replacing cid by base64 + formatting
    data.body = data.body.replaceAll('color:black', '')
    data.body = data.body.replaceAll('color:rgb(0,0,0)', '')
    data.body = data.body.replaceAll(/background-color:rgb\(\d*,\d*,\d*\)/ig, ' ')
    data.body = data.body.replaceAll(/background:[a-zA-Z]*/ig, '')

    data.emailAttachments && data.emailAttachments.map(emailAttachment => {
        data.body = data.body.replaceAll(
            `cid:${emailAttachment.contentId}`,
            `data:${emailAttachment.contentType};base64, ${emailAttachment.contentBytes}`)
    })
    // for (const attachment in data.emailAttachments) {
    //     data.body = data.body.replaceAll(
    //         `cid:${data.emailAttachments[attachment].contentId}`,
    //         `data:${data.emailAttachments[attachment].contentType};base64, ${data.emailAttachments[attachment].contentBytes}`)
    // }
    return data
}