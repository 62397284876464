import * as Msal from 'msal';

const msalConfig = {
    auth: {
        clientId: "818f3c47-61ec-4252-90bb-ca94845d748c",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: window.location.origin + "/redirect",
    },
    cache: {
        cacheLocation: "localStorage", 
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback(loglevel, message, containsPii) {
                console.log(message);
            },
        piiLoggingEnabled: false,
        logLevel: Msal.LogLevel.Verbose,
        },
    },
};  

const authRequestOfflineScope = {
  scopes: ['offline_access'], // Autorisation d'accès hors ligne
};
//"Calendars.ReadWrite", "Contacts.Read",
const authRequestCommonScopes = {
    scopes: [   
        "User.Read", 
        "Mail.Send.Shared", "Mail.ReadWrite.Shared",
        "Calendars.ReadWrite.Shared",
        "Contacts.ReadWrite.Shared" 
    ]
};

const myMSALObj = new Msal.UserAgentApplication(msalConfig);

async function signIn(offlineAccess = false) {
    const scopes = offlineAccess ? { ...authRequestCommonScopes, ...authRequestOfflineScope } : authRequestCommonScopes;
    return await myMSALObj.loginPopup(scopes).then(loginResponse => {
        console.log("id_token acquired at: " + new Date().toString());
        console.log(loginResponse);
        
        if (myMSALObj.getAccount()) {
            // console.log(myMSALObj.getAccount());
        }
    }).catch(error => {
        console.log(error);
        return Promise.reject(error);
    });
}

function signOut() {
    myMSALObj.logout();
}

// check if user is logged in, if not, login
function verifyIsLoggedInOrLogin() {
    
    let account = myMSALObj.getAccount();
    if (account) {
        // console.log("user is logged in with token valid until " + new Date(account.idToken.exp * 1000).toString());
        return Promise.resolve(true)
    } else {
        // console.log("user is not logged in, logging in");
        return Promise.resolve(signIn());
    }
}


// check if user is logged in
function verifyIsLoggedIn() {
    let account = myMSALObj.getAccount();
    if (account) {
        // console.log("user is logged in with token valid until " + new Date(account.idToken.exp * 1000).toString());
        return Promise.resolve("User is logged in");
    } else {
        // console.log("user is not logged in");
        return Promise.reject("User is not logged in");
    }
}

function getTokenPopup(request) {
    return myMSALObj.acquireTokenSilent(request)
    .catch(error => {
        console.log(error);
        console.log("silent token acquisition fails. acquiring token using popup");
        
        // fallback to interaction when silent call fails
        return myMSALObj.acquireTokenPopup(request)
        .then(tokenResponse => {
            return tokenResponse;
        }).catch(error => {
            console.log(error);
        });
    });
}

function getTokenRedirect(request) {
    return myMSALObj.acquireTokenSilent(request)
    .catch(error => {
        console.log(error);
        console.log("silent token acquisition fails. acquiring token using Redirect");
        
        // fallback to interaction when silent call fails
        return myMSALObj.acquireTokenRedirect(request)
        .then(tokenResponse => {
            return tokenResponse;
        }).catch(error => {
            console.log(error);
        });
    });
}

export { verifyIsLoggedIn, verifyIsLoggedInOrLogin, getTokenPopup, getTokenRedirect, signOut, signIn, authRequestCommonScopes, authRequestOfflineScope}