import { formatMailBody } from 'utils/utils';
import config from '../config';
import {restIO} from "../utils/io";
import graphUserRequest from 'utils/graphUserRequest';

const superagent = require('superagent');

const graphApi = {
    // CREATE
    create: (params) => superagent.post(config.app.apiUrlPublic + '/graph', params),

    postQualifyCodeTicket: async (dataPayload) => {
        let endpoint = config.pf.url + "/pf/mail/upsert"
        let payload = dataPayload
        let responseKeepapi = await restIO({ endpoint, method: "post", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },

    addUserSharedMailbox: async (newMailbox) => {
        try {
            let accessible = await graphApi.userGetSharedMails(newMailbox);
            if (accessible.error) {
                return { error: "mailbox does not exist or user does not have access" };
            }
            let endpoint = config.pf.url + "/pf/mail/addUserSharedMailbox";
            let payload = { newMailbox };
            let responseKeepapi = await restIO({ endpoint, method: "post", payload });
            let { datas, extraData, details, code, error } = responseKeepapi;
            return { datas, extraData, details, code, error };
        } catch (error) {
            return { error: "L'adresse mail n'existe pas ou vous n'y avez pas accès" };
        }
    },


    // READ
    getFile: (id) => superagent.get(config.app.apiUrlPublic + `/graph/file/${id}`),
    getAllFiles: () => superagent.get(config.app.apiUrlPublic + `/graph/files`),

    getSharedMails: async () => {
        let endpoint = config.api.url + '/sharedMails'
        let payload = {}
        let responseKeepapi = await restIO({ endpoint, method: "get", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },

    getPersonalMails: async () => {
        let endpoint = config.api.url + '/personalMails'
        let payload = {}
        let responseKeepapi = await restIO({ endpoint, method: "get", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },

    getMail: async (mailId, mailFolderId) => {
        let endpoint = config.api.url + '/mail'
        let payload = { mailId, mailFolderId }
        let responseKeepapi = await restIO({ endpoint, method: "post", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },


    getUserSharedMailboxes: async (userId) => {
        let endpoint = config.api.url + '/mail/readAllUserSharedMailboxes'
        let payload = { userId }
        let responseKeepapi = await restIO({ endpoint, method: "post", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },

    checkHasBeenTreated: async (mailId) => {
        let endpoint = config.api.url + '/mail/checkHasBeenTreated'
        let payload = { mailId: mailId }
        let responseKeepapi = await restIO({ endpoint, method: "post", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },


    // UPDATE
    moveMail: async (dataPayload) => {
        let endpoint = config.pf.url + "/pf/mail/move"
        let payload = dataPayload
        let responseKeepapi = await restIO({ endpoint, method: "post", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },

    markAsRead: async (mail, read) => {
        if (mail.sharedMailbox) { // shared mailbox
            await graphUserRequest({ endpoint: `/users/${mail.sharedMailbox}/mailFolders/Inbox/messages/${mail.mailId}`, method: "PATCH", body: { isRead: read }, contentType: "application/json" }).then((data) => {
            })
        }
        else if (mail.personnalFlag) {
            await graphUserRequest({ endpoint: `me/mailFolders/Inbox/messages/${mail.mailId}`, method: "PATCH", body: { isRead: read }, contentType: "application/json" }).then((data) => {
            })
        } else {
            let endpoint = config.pf.url + "/pf/mail/markAsRead"
            let payload = { mailId: mail.mailId, read }
            let responseKeepapi = await restIO({ endpoint, method: "post", payload })
            let { datas, extraData, details, code, error } = responseKeepapi
            return { datas, extraData, details, code, error }
        }
    },

    markAsTreated: async (mailId) => {
        let endpoint = config.pf.url + "/pf/mail/markAsTreated"
        let payload = { mailId }
        let responseKeepapi = await restIO({ endpoint, method: "post", payload })
        let { datas, extraData, details, code, error } = responseKeepapi
        return { datas, extraData, details, code, error }
    },



    //* GRAPH USER REQUEST *//

    // READ
    userGetMe: () => graphUserRequest({ endpoint: "me" }).catch((error) => { return { datas: [], error } }),
    userGetPhoto: () => graphUserRequest({ endpoint: "me/photo/$value" }).catch((error) => { return { datas: [], error } }),
    
    userGetMails: async () => {
        let res;
        await graphUserRequest({ endpoint: "me/mailFolders/Inbox/messages?$top=100&$select=subject,sender,from,ccRecipients,webLink,toRecipients,conversationId,categories,createdDateTime,hasAttachments,isRead,importance" })
            .then((datas) => {
                if (datas.value?.length > 0) {
                    datas.datas = datas.value.map(data => {
                        return {
                            mailId: data.id,
                            subject: data.subject,
                            sender: data.sender,
                            from: data.from,
                            ccRecipients: data.ccRecipients,
                            webLink: data.webLink,
                            toRecipients: data.toRecipients,
                            conversationId: data.conversationId,
                            categories: data.categories,
                            createdDateTime: data.createdDateTime,
                            hasAttachments: data.hasAttachments,
                            isRead: data.isRead,
                            readDateTime: data.readDatetime,
                            importance: data.importance,
                            personnalFlag: true
                        }
                    })
                    delete datas.value
                }
                res = datas
            }).catch((error) => { res = { datas: [], error } })
        return res
    },

    userGetSharedMails: async (mailbox) => {
        let res;
        await graphUserRequest({ endpoint: `/users/${mailbox}/mailFolders/Inbox/messages?$top=100&$select=subject,sender,from,ccRecipients,webLink,toRecipients,conversationId,categories,createdDateTime,hasAttachments,isRead,importance` })
            .then((datas) => {
                if (datas.value?.length > 0) {
                    datas.datas = datas.value.map(data => {
                        return {
                            mailId: data.id,
                            subject: data.subject,
                            sender: data.sender,
                            from: data.from,
                            ccRecipients: data.ccRecipients,
                            webLink: data.webLink,
                            toRecipients: data.toRecipients,
                            conversationId: data.conversationId,
                            categories: data.categories,
                            createdDateTime: data.createdDateTime,
                            hasAttachments: data.hasAttachments,
                            isRead: data.isRead,
                            readDateTime: data.readDatetime,
                            importance: data.importance,
                            sharedMailbox: mailbox
                        }
                    }
                    )
                    delete datas.value
                }
                res = datas
            }).catch((error) => { res = { datas: [], error } })
        return res
    },



    userGetMail: async (id) => {
        let res;
        await graphUserRequest({ endpoint: `me/messages/${id}?$select=subject,sender,from,ccRecipients,webLink,toRecipients,conversationId,categories,createdDateTime,hasAttachments,isRead,importance,body&$expand=attachments` })
            .then((data) => {
                res = {
                    mailId: data.id,
                    conversationId: data.conversationId,
                    subject: data.subject,
                    sender: data.sender,
                    from: data.from,
                    toRecipients: data.toRecipients,
                    ccRecipients: data.ccRecipients,
                    importance: data.importance,
                    categories: data.categories,
                    webLink: data.webLink,
                    isRead: data.isRead,
                    readDateTime: data.readDateTime,
                    body: data.body.content,
                    hasAttachments: data.hasAttachments,
                    emailAttachments: data.attachments,
                    createdDateTime: data.createdDateTime,
                    personnalFlag: true,
                    emailAttachment: data.attachments
                }
                formatMailBody(res)
            }).catch((error) => { res = { datas: [], error } })

        return res
    },

    userGetSharedMail: async (mailbox, id) => {
        let res;
        await graphUserRequest({ endpoint: `users/${mailbox}/messages/${id}?$select=subject,sender,from,ccRecipients,webLink,toRecipients,conversationId,categories,createdDateTime,hasAttachments,isRead,importance,body&$expand=attachments` })
            .then((data) => {
                res = {
                    mailId: data.id,
                    conversationId: data.conversationId,
                    subject: data.subject,
                    sender: data.sender,
                    from: data.from,
                    toRecipients: data.toRecipients,
                    ccRecipients: data.ccRecipients,
                    importance: data.importance,
                    categories: data.categories,
                    webLink: data.webLink,
                    isRead: data.isRead,
                    readDateTime: data.readDateTime,
                    body: data.body.content,
                    hasAttachments: data.hasAttachments,
                    emailAttachments: data.attachments,
                    createdDateTime: data.createdDateTime,
                    sharedMailbox: mailbox,
                    emailAttachment: data.attachments
                }
                formatMailBody(res)
            }).catch((error) => { res = { datas: [], error } })

        return res
    },


    userGetMeetings: () => graphUserRequest({ endpoint: "me/events" }),
    
    userGetCalendars: () => graphUserRequest({ endpoint: "me/calendars" }),
    userGetCalendarEvents: (calendarId) => graphUserRequest({ endpoint: `me/calendars/${calendarId}/events` }),
    userGetContacts: () => graphUserRequest({ endpoint: "me/contacts" }),
    userGetGroups: () => graphUserRequest({ endpoint: "me/memberOf" }),
    userGetFiles: () => graphUserRequest({ endpoint: "me/drive/root/children" }),
    userGetDrive: () => graphUserRequest({ endpoint: "me/drive" }),
    userGetDriveItems: () => graphUserRequest({ endpoint: "me/drive/items" }),
    userGetDriveRecent: () => graphUserRequest({ endpoint: "me/drive/recent" }),
    userGetDriveShared: () => graphUserRequest({ endpoint: "me/drive/sharedWithMe" }),

    userGetBatch: (batch) => graphUserRequest({ method: "POST", endpoint: "$batch", body: batch, contentType: "application/json" }),
}

export default graphApi
