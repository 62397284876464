import {
  AppBar,
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";

import { PDrawerContext } from "stores/PDrawerProvider";
import { Link, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { KeyboardArrowDownRounded, Logout, Search } from "@mui/icons-material";
import theme from "assets/jss/theme";
import { KMSessionUserContext } from "stores/KMSessionUserProvider";
import messageApi from "api/messageApi";
import { KMInfraContext } from "stores/KMInfraProvider";
import NestedMessage from "./NestedMessage";
import { lightOrDark } from "utils/utils";
import graphApi from "api/graphApi";
import ticketApi from "api/ticketApi";
import logoKeepapi from "assets/img/Keepapi1.png";

const KMenu = (props) => {
  let routes = props.routes;
  routes = routes.sort((a, b) => a.routeOrder - b.routeOrder);
  const { sessionUserStore } = useContext(KMSessionUserContext);
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(null);
  let { addLayer, removeLayer, removeEveryLayer } = useContext(PDrawerContext);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorElNotification, setAnchorElNotification] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const handleOpenNotificationMenu = (event) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };

  const fetchNotifications = () => {
    messageApi.getAll().then((data) => {
      setNotifications(data.datas);
    });
  };

  // fetch notifications
  useEffect(() => {
    fetchNotifications();

    const intervalCall = setInterval(() => {
      fetchNotifications();
    }, 15000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  const [avatar, setAvatar] = useState("");
  // useEffect(() => {
  //   setAvatar(sessionUserStore.microsoftUser?.avatar)
  // }, [sessionUserStore.microsoftUser])

  const themeMode =
    sessionUserStore.clientId === 1
      ? "light"
      : localStorage.getItem("theme-mode");

  const handleMeetings = async () => {
    // discord({ level: "meeting", message: subject, errorInfo: { URL: meeting.joinUrl, meetingCode: meeting.meetingCode, subject: meeting.subject, startDateTime: meeting.startDateTime, endDateTime: meeting.endDateTime, creator: sessionUserStore.userEmail } });

    if (
      window.confirm(
        "Voulez-vous prendre rendez-vous avec un membre de l'équipe de développement ?"
      )
    ) {
      const subject = window.prompt("Veuillez entrer le sujet de la réunion :");
      graphApi.createMeeting({ subject }).then((meeting) => {
        meeting = meeting.datas;
        if (meeting.joinUrl) window.open(meeting.joinUrl, "_blank").focus();
      });
    }
  };

  const AutoCompleteSearch = () => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);

    useEffect(() => {
      if (inputValue === "") {
        setOptions([]);
        return undefined;
      }

      const search = async () => {
        const tickets = await ticketApi.search(inputValue);
        setOptions(
          tickets.datas.map((ticket) => {
            return {
              label: ticket.ticketId + "-" + ticket.ticketLabel,
              value: ticket.ticketId,
            };
          })
        );
      };

      search();
    }, [inputValue]);

    return (
      <Autocomplete
        sx={{ width: 300, mr: 5 }}
        value={value}
        onChange={(event, newValue) => {
          if (newValue) {
            addLayer({
              layerType: "temporaryDrawer",
              layerAnchor: "right",
              layerRoutePath: "/ticket/stepper/read/" + newValue.value,
              layerCallbackOnClose: () => {},
              layerCallbackOnAction: () => {},
            });
          }
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        getOptionLabel={(option) => option.label}
        noOptionsText="Aucun ticket trouvé"
        renderOption={(props, option) => {
          return (
            <Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Typography variant="body2" fontWeight={500}>
                    {option.label}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        }}
        renderInput={(params) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              {...params}
              placeholder="Chercher un ticket"
              InputProps={{ ...params.InputProps }}
            />
          </Box>
        )}
      />
    );
  };

  return (
    <>
      <Container
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 99998,
          backgroundColor: sessionUserStore.clientColorPrimary,
        }}
        maxWidth="false"
      >
        {/* <Toolbar disableGutters sx={{ backgroundColor: sessionUserStore.clientColorPrimary }}> */}
        <Toolbar
          disableGutters
          sx={{ backgroundColor: sessionUserStore.clientColorPrimary }}
        >
          <img
            style={{ maxWidth: "70px", height: "auto", marginRight: "10px" }}
            src={logoKeepapi}
            alt="logo Keepapi"
          />

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex", lg: "none" },
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon sx={{ color: sessionUserStore.clientColorSecondary }} />
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "block", lg: "none" },
              }}
            >
              {routes.map((route) => (
                <MenuItem key={route.routeId} onClick={handleCloseNavMenu}>
                  <Link key={route.routeId} to={route.routePath}>
                    <Typography
                      color={themeMode === "light" ? "black" : "white"}
                      textAlign="center"
                    >
                      {route.routeLabel}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "none", lg: "flex" },
            }}
          >
            {/* <img src={assets.images.logoDark} style={{ width: '5%', height: "5%" }} alt='app logo' /> */}
            {routes.map((route) => (
              <Button
                key={route.routeId}
                onClick={() => navigate(route.routePath)}
              >
                <Typography
                  textAlign="center"
                  color={
                    lightOrDark(sessionUserStore.clientColorPrimary) === "dark"
                      ? "white"
                      : "black"
                  }
                >
                  {route.routeLabel}
                </Typography>
              </Button>
            ))}
          </Box>

          <AutoCompleteSearch />

          <Button
            sx={{ mr: 5 }}
            variant="contained"
            color="secondary"
            onClick={() => {
              addLayer({
                layerType: "temporaryDialog",
                layerAnchor: "right",
                layerRoutePath: "/newMeeting?eventId=0",
                layerCallbackOnClose: () => {},
                layerCallbackOnAction: () => {},
              });
            }}
          >
            Réunion Teams
          </Button>

          {/* <Button sx={{ mr: 5 }} variant="contained" color='secondary' onClick={() => {}}>Demande de rappel
          </Button> */}

          <Button
            sx={{ mr: 5 }}
            variant="contained"
            color="secondary"
            onClick={() =>
              addLayer({
                layerType: "temporaryDialog",
                layerAnchor: "right",
                layerRoutePath: "/newTicket?ticketId=0",
                layerCallbackOnClose: () => {},
                layerCallbackOnAction: () => {},
              })
            }
          >
            Créer un ticket
          </Button>
          <Box sx={{ flexGrow: 0 }}>
            {sessionUserStore.clientId !== 1 && (
              <Tooltip
                title={themeMode === "light" ? "Dark Mode" : "Light Mode"}
              >
                <IconButton onClick={() => props.changeThemeMode()}>
                  {themeMode === "light" ? (
                    <DarkModeRoundedIcon sx={{ color: "#1b1b1b" }} />
                  ) : (
                    <LightModeRoundedIcon sx={{ color: "#1b1b1b" }} />
                  )}
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Notifications">
              <IconButton size="large" onClick={handleOpenNotificationMenu}>
                <Badge badgeContent={notifications.length} color="red">
                  <NotificationsIcon
                    sx={{ color: sessionUserStore.clientColorSecondary }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNotification}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNotification)}
              onClose={handleCloseNotificationMenu}
              sx={{
                display: { xs: "block" },
              }}
            >
              {notifications.length > 0 &&
                notifications.map((message) => (
                  <NestedMessage
                    notifications={notifications}
                    message={message}
                    setNotifications={setNotifications}
                    handleCloseNotificationMenu={handleCloseNotificationMenu}
                    key={message.messageId}
                  /> // Problème de clé react
                ))}
              {notifications.length === 0 && (
                <MenuItem sx={{ width: "400px", minHeight: "40px" }}>
                  Aucune notification
                </MenuItem>
              )}
            </Menu>
            {sessionUserStore.clientId !== 1 && (
              <>
                <IconButton color="secondary" onClick={() => navigate("/me")}>
                  <Avatar
                    sx={{
                      backgroundColor: sessionUserStore.clientColorSecondary,
                      color: sessionUserStore.clientColorPrimary,
                    }}
                    alt="User"
                    src={avatar}
                  />{" "}
                  {/* TODO : Siwtch avec vrai docid avatar  */}
                </IconButton>
                <IconButton onClick={() => navigate("/logout")}>
                  <Logout
                    sx={{ color: sessionUserStore.clientColorSecondary }}
                  />
                </IconButton>
              </>
            )}
          </Box>
        </Toolbar>
      </Container>
    </>
  );
};

export default KMenu;
