import { restIO } from 'utils/io';
import config from '../config';
const superagent = require('superagent');

const ticketApi = {
  // CREATE 
  newTicket: async ({ ticketId, ticketLabel, ticketNoteMessageHTML, typingCodeTicket, typingCodeBugState, typingCodeFunctionalityState, formFiles }) => {
    let apiUrl = config.pf.url + "/pf/ticket/upsert";
    let payload = {
        ticketId,
        ticketLabel,
        ticketNoteMessageHTML,
        typingCodeTicket,
        typingCodeBugState,
        typingCodeFunctionalityState,
        formFiles
    };
    let responseKeepapi = await restIO({
        endpoint: apiUrl,
        method: "post",
        payload,
        jwt: true,
    });
    let { datas, extraData, details, code, error, showBackendMessage = false, message: backendMessage} = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  // COM
  confirmResolution: async ({ ticketId, userEmail }) => {
    let apiUrl = config.com.url + "/com/ticket/confirmResolution";
    let payload = {ticketId, userEmail};
    let responseKeepapi = await restIO({
        endpoint: apiUrl,
        method: "post",
        payload,
        jwt: true,
    });
    let { datas, extraData, details, code, error, showBackendMessage = false, message: backendMessage } = responseKeepapi
    return { datas, extraData, details, code, error }
  },


  askToConfirm: async ({ ticketId, userEmail }) => {
    let apiUrl = config.com.url + "/com/ticket/askToConfirm";
    let payload = {ticketId, userEmail};
    let responseKeepapi = await restIO({
        endpoint: apiUrl,
        method: "post",
        payload,
        jwt: true,
    });
    let { datas, extraData, details, code, error, showBackendMessage = false, message: backendMessage } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  

  newNote: (params) => superagent.post(config.app.apiUrlPublic + `/ticketNote`, params),

  // READ
  getAll: async () => {
    let endpoint = config.api.url + `/tickets`
    let payload = {}
    let responseKeepapi = await restIO({ endpoint, method: "get", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },
  getAllActive: async () => {
    let endpoint = config.api.url + `/tickets/active`
    let payload = {}
    let responseKeepapi = await restIO({ endpoint, method: "get", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },
  getFromClient: (clientId) => superagent.get(config.app.apiUrlPublic + `/ticket/client/${clientId}`),
  getFromUser: (userId) => superagent.get(config.app.apiUrlPublic + `/ticket/user/${userId}`),
  getOne: async (ticketId) => {
      let endpoint = config.api.url + `/tickets/${ticketId}/read`
      let payload = {}
      let responseKeepapi = await restIO({ endpoint, method: "get", payload })
      let { datas, extraData, details, code, error } = responseKeepapi
      return { datas, extraData, details, code, error } 
    },

  search: async (search) => {
    let endpoint = config.api.url + `/tickets/search`
    let payload = {search}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  getTicketLogs: async (ticketId) => {
    let endpoint = config.api.url + `/tickets/${ticketId}/logs`
    let payload = {}
    let responseKeepapi = await restIO({ endpoint, method: "get", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  getAllTicketsDeployed: async () => {
    let endpoint = config.api.url + `/tickets/readAllDeployed`
    let payload = {}
    let responseKeepapi = await restIO({ endpoint, method: "get", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  getAllLogs: async () => {
    let endpoint = config.api.url + `/tickets/logs`
    let payload = {}
    let responseKeepapi = await restIO({ endpoint, method: "get", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  getOneNotes: (ticketId) => superagent.get(config.app.apiUrlPublic + `/ticketNote/${ticketId}`),

  getRoadmaps: async () => {
    let endpoint = config.api.url + `/tickets/roadmaps`
    let payload = {}
    let responseKeepapi = await restIO({ endpoint, method: "get", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error } 
  },

  // UPDATE
  updateOrder: async ({sourceTickets,destinationTickets,sourceSectionId,destinationSectionId,ticketId}) => {
      let endpoint = config.pf.url + `/pf/ticket/updateOrder`
      let payload = {
        resourceList: sourceTickets,
        destinationList: destinationTickets,
        resourceSectionId: sourceSectionId,
        destinationSectionId: destinationSectionId,
        ticketId
      }
      let responseKeepapi = await restIO({ endpoint, method: "post", payload })
      let { datas, extraData, details, code, error } = responseKeepapi
      return { datas, extraData, details, code, error }
  },
  update: (ticketId, params) => superagent.put(config.app.apiUrlPublic + `/ticket/${ticketId}`, params),
  changeTypingCode: async ({ticketId, to}) => {
    let endpoint = config.pf.url + `/pf/ticket/changeTypingCode`
    let payload = {ticketId, to}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error } 
  }, 
  typeSwitch: async (ticketId) => {
    let endpoint = config.pf.url + `/pf/ticket/typeSwitch`
    let payload = {ticketId}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },
  updateNotepad: async ({ticketId, ticketNotepadHTML}) => {
    let endpoint = config.pf.url + `/pf/ticket/updateNotepad`
    let payload = {ticketId: parseInt(ticketId), ticketNotepadHTML}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  

  // SWITCH
  setAnalysed: async (ticketId) => {
    let endpoint = config.pf.url + `/pf/ticket/analyse`
    let payload = {ticketId}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error } 
  }, 

  setQualified: async (ticketId, typingCodeEstimatedTimeRange) => {
    let endpoint = config.pf.url + `/pf/ticket/qualify`
    let payload = {ticketId, typingCodeEstimatedTimeRange}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  setDevelopped: async (ticketId) => {
    let endpoint = config.pf.url + `/pf/ticket/develop`
    let payload = {ticketId}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  setValidated: async (ticketId) => {
    let endpoint = config.pf.url + `/pf/ticket/validate`
    let payload = {ticketId}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  addCurrentUser: async (ticketId) => {
    let endpoint = config.pf.url + `/pf/ticket/addUser`
    let payload = {ticketId}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  // DELETE
  archive: async (ticketId) => {
    let endpoint = config.pf.url + `/pf/ticket/archive`
    let payload = {ticketId}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },

  unarchive: async (ticketId) => {
    let endpoint = config.pf.url + `/pf/ticket/unarchive`
    let payload = {ticketId}
    let responseKeepapi = await restIO({ endpoint, method: "post", payload })
    let { datas, extraData, details, code, error } = responseKeepapi
    return { datas, extraData, details, code, error }
  },
}

export default ticketApi