import React, { createContext, Component, useEffect, useState } from 'react'
import config from 'config.js'
import { restIO } from 'utils/io'
import clientApi from 'api/userApi'
import graphApi from 'api/graphApi'
import { verifyIsLoggedIn } from 'utils/graphUserAuthUtils'

export const KMSessionUserContext = createContext({
  sessionUserStore: {},
  sessionUserStoreRightPermissionDenied: true,
})

// class KMSessionUserProvider extends Component {
//   constructor(props) {
//     super(props)
//
//     this.state = {
//       sessionUserStore: {},
//       sessionUserStoreRightPermissionDenied: true,
//     }
//   }

const KMSessionUserProvider = ({ enqueueSnackbar, children }) => {
  // this.state = {
  //   sessionUserStore: {},
  //   sessionUserStoreRightPermissionDenied: true,
  // }
  const [sessionUserStore, setSessionUserStore] = useState({})
  const [sessionUserStoreRightPermissionDenied, setSessionUserStoreRightPermissionDenied] = useState(true)
  const initSessionUser = async () => {
    // let { enqueueSnackbar } = props
    let endpoint = config.api.session.user.url
    let responseKeepapi
    try {
      responseKeepapi = await restIO({ endpoint, jwt: true })
    } catch (error) { // network error && 401 not authenticated
      let message = `erreur réseau 202011292135 ${error}`
      return false
    }
    let { datas, code } = responseKeepapi
    if (code !== 'ok') {
      let message = `impossible de récuperer la fiche utilisateur. iam policies missing`
      setSessionUserStore({})
      setSessionUserStoreRightPermissionDenied(true)
      return false
    }
    if (datas.length > 0) {
      // traitement des droits dynamic https://stackoverflow.com/questions/36517173/how-to-store-a-javascript-function-in-json
      // voir aussi le fichier rbacRules qui serialize en string les fonctions dynamic
      if (sessionUserStore.rbac && sessionUserStore.rbac.dynamic && Array.isArray(sessionUserStore.rbac.dynamic)) {
        sessionUserStore.rbac.dynamic = sessionUserStore.rbac.dynamic.map(dynamic => {
          return JSON.parse(dynamic, (key, value) => typeof value === "string" ? eval("(" + value + ")") : value)
        })
      }
      localStorage.setItem('client-primary-color', datas[0].clientColorPrimary)
      localStorage.setItem('client-secondary-color', datas[0].clientColorSecondary)
       await verifyIsLoggedIn()
        .then(async () => {
          return await graphApi.userGetMe()
            .then((user) => {
              console.log(user);
              graphApi.userGetPhoto()
                .then((blob) => {
                  let reader = new FileReader();
                  try {
                    // blob to base64
                    reader.readAsDataURL(blob);
                  } catch (error) {
                    console.log(error); // user does not have a photo
                  } finally {
                    datas[0].microsoftUser = { ...user, avatar: reader.result };
                    setSessionUserStore(datas[0]);
                    setSessionUserStoreRightPermissionDenied(false);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            });
        })
        .catch((error) => {
          console.log(error);
          setSessionUserStore(datas[0]);
          setSessionUserStoreRightPermissionDenied(false);
        });
      return false
    }
    setSessionUserStore({})
    setSessionUserStoreRightPermissionDenied(true)
    return false
  }
  useEffect(() => {
    initSessionUser()
  }, [])

  return (
    <KMSessionUserContext.Provider value={{ sessionUserStore, sessionUserStoreRightPermissionDenied }}>
      {children}
    </KMSessionUserContext.Provider>
  )
}
export { KMSessionUserProvider }


export const withSessionUserStore = Component => props => (
  <KMSessionUserContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMSessionUserContext.Consumer>
)
